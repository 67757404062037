function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function(){


	// ANCRES DOUCES

	function juizScrollTo(element){			
		$(element).click(function(){
			var goscroll = false;
			var the_hash = $(this).attr("href");
			var regex = new RegExp("\#(.*)","gi");
			var the_element = '';
	 
			if(the_hash.match("\#(.+)")) {
				the_hash = the_hash.replace(regex,"$1");
	 
				if($("#"+the_hash).length>0) {
					the_element = "#" + the_hash;
					goscroll = true;
				}
				else if($("a[name=" + the_hash + "]").length>0) {
					the_element = "a[name=" + the_hash + "]";
					goscroll = true;
				}
	 
				if(goscroll) {
					$('html, body').animate({
						scrollTop:$(the_element).offset().top - 145
					}, 'slow');
					return false;
				}
			}
		});					
	};
	juizScrollTo('a[href^="#"]');


	// BacktoTop Listing Project

	function displayBackToTop(){
		if($('.selectionContainer').length != '0'){
			var bottomAffiner = $('.selectionContainer').offset().top + $('.selectionContainer').outerHeight(true);
			var window_top = $(window).scrollTop();

			if (window_top > bottomAffiner){
		        $('.backToTop').addClass('active');
		    }else{
		        $('.backToTop').removeClass('active');
		    }
	    } 
	}

	

	// HOME SYNC CAROUSEL

	

	var sync1 = $("#sync1");
	var sync2 = $("#sync2");

  	sync1.owlCarousel({
	    items : 1,
	    nav: false,
	    dots: false,
	    loop: true,
	    pullDrag: false,
	    mouseDrag: false,
	    freeDrag: false,
	    touchDrag: false
  	});

  	sync2.owlCarousel({
	    items : 1,
	    dots: true,
	    nav: false,
	    loop: true,
	    pullDrag: false,
	    mouseDrag: false,
	    freeDrag: false,
	    touchDrag: false
  	});

  	// Go to the next item
	    $('.owlNextProject').click(function() {
	        sync1.trigger('next.owl.carousel');
	        sync2.trigger('next.owl.carousel');
	    })

	    // Go to the previous item
	    $('.owlPrevProject').click(function() {
	        sync1.trigger('prev.owl.carousel');
	        sync2.trigger('prev.owl.carousel');
	    });


	var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owlCarouselBloc').each(function(){

     	var newID = 'owlCarouselBloc' + IDnumber;

    	$(this).attr('id', newID);
    	IDnumber++;
     
    	var owl = $('#' + newID);


    	owl.owlCarousel({
    		loop:true,
		    nav:false,
		    items: 1,
		    dots: false,
		    mouseDrag: false,
		    touchDrag: false,
		    pullDrag: false,
		    freeDrag: false
    	});

    	owl.trigger('refresh.owl.carousel');

    	$(this).next().addClass('owlNextBloc' + NEXTPREVnumber)
    	$(this).next().next().addClass('owlPrevBloc' + NEXTPREVnumber)

    	// Go to the next item
	    $('.owlNextBloc' + NEXTPREVnumber).click(function() {
	        owl.trigger('next.owl.carousel');
	    })

	    // Go to the previous item
	    $('.owlPrevBloc' + NEXTPREVnumber).click(function() {
	        // With optional speed parameter
	        // Parameters has to be in square bracket '[]'
	        owl.trigger('prev.owl.carousel', [300]);
	    });

	    NEXTPREVnumber++;

    });



	// HEIGHT FRIEZE

	function friezeHeight(){
	
		$('.blocFrieze').each(function(){

			var totalHeight = 50;

			$(this).find('.blocFrieze-content').each(function(){
				totalHeight += $(this).outerHeight(true);
			});

			$(this).find('.blocFrieze-background').css('height', totalHeight);

		});
	}

	friezeHeight();
	$( window ).resize(friezeHeight);

	$('.menuItem').click(function(){
		if ($(window).width() < 1200) {

			var submenuHeight = $(this).find('.submenuContainer')

	 		if($(this).hasClass('active')){
				$('.menuItem').removeClass('active');
				$('.submenuContainer').css('height', '0px');
			
			} else {

				var totalHeight = 0;
				$(this).find(".submenuContainer").children().children().each(function(){
				    totalHeight += $(this).outerHeight(true);
				});

				$('.menuItem').removeClass('active');
				$(this).addClass('active');
				$('.submenuContainer').css('height', '0px');
				$(this).find('.submenuContainer').css('height', totalHeight);
			}
		} else {
			$('.menuItem').removeClass('active');
			$('.submenuContainer').css('height', '');
		}
	});

	$('.toggleMenu').click(function(){
		$('.menu').toggleClass('active');
		$('.menuItem').removeClass('active');
		$('.submenuContainer').css('height', '0px');
		$(this).toggleClass('active');
	});

	function resetMenu(){
		if ($(window).width() >= 1200) {
			$('.menuItem').removeClass('active');
			$('.submenuContainer').css('height', '');
		}
	}
	function activeParallaxScroll(){
		if ($(window).width() >= 768) {
			$('.slide').addClass('above768');
			$('header').removeClass('under768');
		} else {
			$('.slide').removeClass('above768');
			$('header').addClass('under768');

		}
	}

	resetMenu();
	activeParallaxScroll();
	$( window ).resize(resetMenu);
	$( window ).resize(activeParallaxScroll);


	// Sticky header on mobile 

	function stickyHeaderOnMobile(){
	    if($('body').hasClass('home')){
	    
		    var windowTop = $(window).scrollTop();
		    var topDiv = $(".slide1").offset().top + $(".slide1").outerHeight(true) - 95;

		    if($('header').hasClass('under768')){

		        if(topDiv <= windowTop) {
		            $("header").addClass("sticky");
		        } else {
		            $("header").removeClass("sticky");
		        }
		    } else {
		        $("header").removeClass("sticky");
		    }
		}
	}

		stickyHeaderOnMobile();
		$( window ).resize(stickyHeaderOnMobile);
		$( window ).scroll(stickyHeaderOnMobile);


	// Test full slide


	// ------------- VARIABLES ------------- //
	 
	var ticking = false;
	var isFirefox = (/Firefox/i.test(navigator.userAgent));
	var isIe = (/MSIE/i.test(navigator.userAgent)) || (/Trident.*rv\:11\./i.test(navigator.userAgent));
	var scrollSensitivitySetting = 30; //Increase/decrease this number to change sensitivity to trackpad gestures (up = less sensitive; down = more sensitive) 
	var slideDurationSetting = 800; //Amount of time for which slide is "locked"
	var currentSlideNumber = 0;
	var totalSlideNumber = $(".slide").length;


	// ------------- DETERMINE DELTA/SCROLL DIRECTION ------------- //
 
	function parallaxScroll(evt) {
	 
	    if (isFirefox) {
	        //Set delta for Firefox
	        delta = evt.detail * (-120);
	 
	    } else if (isIe) {
	        //Set delta for IE
	        delta = -evt.deltaY;
	 
	    } else {
	        //Set delta for all other browsers
	        delta = evt.wheelDelta;
	    }
	 
	    if (ticking != true) {
	 
	        if (delta <= -scrollSensitivitySetting) {
	 
	            //Down scroll
	            ticking = true;
	 
	            if (currentSlideNumber !== totalSlideNumber - 1) {
	                currentSlideNumber++;
	                nextItem();
	            }
	            slideDurationTimeout(slideDurationSetting);
	 
	        } if (delta >= scrollSensitivitySetting) {
	 
	            //Up scroll
	            ticking = true;
	 
	            if (currentSlideNumber !== 0) {
	                currentSlideNumber--;
	            }
	            previousItem();
	            slideDurationTimeout(slideDurationSetting);
	        } 
	    }
	}

	// ------------- DETERMINE TOUCHMOVE DIRECTION ------------- //

	var touchstartpoint;

	$(document).bind('touchstart', function (e){
	    touchstartpoint = e.originalEvent.touches[0].clientY;
	});

	$(document).bind('touchend', function (e){
	    
	    if (ticking != true) {

	        var touchendpoint = e.originalEvent.changedTouches[0].clientY;
	        
	        if(touchstartpoint > touchendpoint+5){
	            //Down scroll
	            ticking = true;
	            
	            if (currentSlideNumber !== totalSlideNumber - 1) {
	                currentSlideNumber++;
	                nextItem();
	            }
	            
	            slideDurationTimeout(slideDurationSetting);
	           // setDelaysFromTheTop();

	        } else if (touchstartpoint < touchendpoint-5){
	          
	            //Up scroll
	            ticking = true;
	          
	            if (currentSlideNumber !== 0) {
	                currentSlideNumber--;
	            }
	            
	            previousItem();
	            slideDurationTimeout(slideDurationSetting);
	            //setDelaysFromTheBottom();
	        
	        }
	    }
	});

	$(document).keydown(function(e){
	    if (e.which == 40) { // down
	         if (currentSlideNumber !== totalSlideNumber - 1) {
	                currentSlideNumber++;
	                nextItem();
	            }
	            
	            slideDurationTimeout(slideDurationSetting);

	       return false;
	    }
	});

	$(document).keydown(function(e){
	    if (e.which == 38) { // up
	 
	        if (currentSlideNumber !== 0) {
	            currentSlideNumber--;
	        }
	        
	        previousItem();
	        slideDurationTimeout(slideDurationSetting);

	       return false;
	    }
	});


	// ------------- SET TIMEOUT TO TEMPORARILY "LOCK" SLIDES ------------- //
	 
	function slideDurationTimeout(slideDuration) {
	 
	    setTimeout(function() {
	        ticking = false;
	    }, slideDuration);
	 
	}

	// ------------- ADD EVENT LISTENER ------------- //
	 
	var mousewheelEvent = isFirefox ? "DOMMouseScroll" : "wheel";
	window.addEventListener(mousewheelEvent, _.throttle(parallaxScroll, 60), false);


	// ------------- SLIDE MOTION ------------- //
	 
	function nextItem() {
		if($('body').hasClass('home')){

		    var $previousSlide = $(".slide").eq(currentSlideNumber - 1);
		    var $currentSlide = $(".slide").eq(currentSlideNumber);

		    if(currentSlideNumber > 0){
		    	$('header').removeClass('bottom');
		    }

		    var $currentDot = $('.dot').eq(currentSlideNumber);
		    var $notCurrentSlide = $(".slide").eq(!currentSlideNumber);
		    $previousSlide.removeClass("up-scroll").addClass("down-scroll");
		    $currentSlide.addClass("current fromTheTop");
		    $notCurrentSlide.removeClass("current").removeClass("fromTheBottom").removeClass("fromTheTop");
		    $previousSlide.removeClass("current").removeClass("fromTheBottom").removeClass("fromTheTop");
		    $('.dot').removeClass('current');
		    $currentDot.addClass('current');  
		}
	}
	 
	function previousItem() {
		if($('body').hasClass('home')){

		    var $nextSlide = $(".slide").eq(currentSlideNumber + 1);
		    var $currentSlide = $(".slide").eq(currentSlideNumber);

		    if(currentSlideNumber < 1){
		    	$('header').addClass('bottom');
		    }
		    
		    var $notCurrentSlide = $(".slide").eq(!currentSlideNumber);
		    $currentSlide.removeClass("down-scroll").addClass("up-scroll");
		    $currentSlide.addClass("current fromTheBottom");
		    $notCurrentSlide.removeClass("current").removeClass("fromTheBottom").removeClass("fromTheTop");
		    $nextSlide.removeClass("current").removeClass("fromTheBottom").removeClass("fromTheTop");
		    var $currentDot = $('.dot').eq(currentSlideNumber);
		    $('.dot').removeClass('current');
		    $currentDot.addClass('current');
		}
	}

	var $currentSlide = $(".slide").eq(currentSlideNumber);
	$currentSlide.addClass("current");

	// ------------- Dots ------------- //
     
    var dotNumber = 0;
 
    $('.slide').each(function(){
        $('.dots').append( "<div class='dot anchor' data-goto=" + dotNumber + "><span></span></div>" );
        dotNumber++;
    })
 
    $('.dot:first').addClass('current');
 
    $('.dot').click(function(){
        $('.dot').removeClass('current');
        $(this).addClass('current');
    });   
 
    var $currentDot = $('.dot').eq(currentSlideNumber);
    $('.dot').removeClass('current');
    $currentDot.addClass('current');
 
    // ------------- SMOOTH ANCHOR ------------- //

      $('.anchor').click(function(){

        var gotoSlide = $(this).data('goto');
        var $anchorSlide = $(".slide").eq(gotoSlide);
        var $currentSlide = $(".slide").eq(currentSlideNumber);
        var $notCurrentSlide = $(".slide").eq(!currentSlideNumber);

        if (gotoSlide > currentSlideNumber) {

            //Down scroll

            $anchorSlide.addClass("current fromTheTop");
            $currentSlide.removeClass("current").removeClass("fromTheTop").removeClass("fromTheBottom");

            $('.slide').each(function(){

                var slideNumber = $(this).data('anchor');
                if(slideNumber < gotoSlide){
                    $(this).addClass('down-scroll').removeClass('up-scroll');
                } else if(slideNumber > gotoSlide){
                    $(this).addClass('up-scroll').removeClass('down-scroll');
                }
            })
            
            currentSlideNumber = gotoSlide;

        } else if (gotoSlide < currentSlideNumber) {

            //Up scroll

            $anchorSlide.addClass("current up-scroll fromTheBottom").removeClass('down-scroll');
            $currentSlide.removeClass("current").removeClass("fromTheTop").removeClass("fromTheBottom");

            $('.slide').each(function(){

                var slideNumber = $(this).data('anchor');

                if(slideNumber < gotoSlide){

                    $(this).addClass('down-scroll').removeClass('up-scroll');

                } else if(slideNumber > gotoSlide){

                    $(this).addClass('up-scroll').removeClass('down-scroll');
                }
            })
            
            currentSlideNumber = gotoSlide;
        }

        var $currentDot = $('.dot').eq(currentSlideNumber);
        $('.dot').removeClass('current');
        $currentDot.addClass('current');

        var $currentHeader = $('.headerRight').find('.anchor').eq(currentSlideNumber - 1);
       	$('.anchor').removeClass('active').addClass('inactive');
        $currentHeader.removeClass('inactive').addClass('active');
        $('.underlineAnchor').removeClass('active').addClass('inactive');
        $currentHeader.find('.underlineAnchor').removeClass('inactive').addClass('active');

        if(currentSlideNumber > 0){
	    	$('header').removeClass('bottom');
	    } else if(currentSlideNumber < 1){
        	$('header').addClass('bottom');
        }

    });




    // Owl Carousel Home

    var owl = $('.owl-carousel');
    
    owl.owlCarousel({
	    loop:true,
	    nav:false,
	    items: 1,
	})

    var owl = $('#owl-carousel-home');
    
    owl.owlCarousel();

    // Go to the next item
    $('.owlNext').click(function() {
        owl.trigger('next.owl.carousel');
    })

    // Go to the previous item
    $('.owlPrev').click(function() {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        owl.trigger('prev.owl.carousel', [300]);
    })

    // Owl carousel service

    var owl2 = $('#owl-carousel-service');
    
    owl2.owlCarousel({
	    loop:true,
	    nav:false,
	    items: 1,
	})
    
    owl2.owlCarousel();

    // Go to the next item
    $('.owlNext').click(function() {
        owl2.trigger('next.owl.carousel');
    })

    // Go to the previous item
    $('.owlPrev').click(function() {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        owl2.trigger('prev.owl.carousel', [300]);
    });


    // SYNC ANCHOR WITH CAROUSEL SERVICES
    
    $('.owl-item-service-anchor:first-child').addClass('current');

    var owlItemServiceAnchorLenght = $('.owl-carousel-service').children( ".owl-item-service-anchor" ).length;

    owl2.on('changed.owl.carousel', function(event) {

        var currentItem = event.item.index - 3;
        var countItem = event.item.count;
        if(currentItem == event.item.count){
        	var currentItem = 0;
        }
        var currentItemSync = $('.owl-item-service-anchor').eq(currentItem);
        $('.owl-item-service-anchor').removeClass('current');
        currentItemSync.addClass('current');
    });


    $('.owl-item-service-anchor').click(function(){
    	var linkTo = $(this).index();
	    owl2.trigger('to.owl.carousel', linkTo);
	    $('.owl-item-service-anchor').removeClass('current');
	    $(this).addClass('current');
	});

    owl2.owlCarousel();

    // Go to the next item
    $('.owlNext2').click(function() {
        owl2.trigger('next.owl.carousel');
    });

    // Go to the previous item
    $('.owlPrev2').click(function() {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        owl2.trigger('prev.owl.carousel', [300]);
    });

    $('.search_project input').on('click', function(e) {
    	$('.search_project').submit();
    });

     

    $('select').selectric();

    $('.selectric-wrapper').click(function(){
    	$(this).toggleClass('selectric-open');
    });

        $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#contact_form').length) {
          $('#checkboxContact').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
      if ($('#newsletter_form').length) {
          $('#checkboxFooter').rules('add', {
                validateCgvFooter: true
            });
        }


    }, 100);        



    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#checkboxContact').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $.validator.addMethod("validateCgvFooter", function (value, element) {
            return $('#checkboxFooter').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#contact_form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxContainer');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

        $('#newsletter_form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxContainer');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

	$(window).scroll(displayBackToTop);
	$(window).resize(displayBackToTop);
	displayBackToTop();

	$('.menuItemLink').removeAttr("href");

	$('.owlCarouselBloc').trigger('refresh.owl.carousel');
});



